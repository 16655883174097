<template>
  <div class="wx_misc_set">
    <div>
      <el-row>
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>所有商品页分类设置</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="submitListCategoryInfo">更新</el-button>
            </div>
            <el-tabs>
              <el-tab-pane label="客厅">
                <el-row :gutter="10" class="mgb10" v-for="(item, index) in listpage.kt" :key="index">
                  <el-col :span="10">
                    <el-input size="small" v-model="item.name" placeholder="分类名称"></el-input>
                  </el-col>
                  <el-col :span="10">
                    <el-select v-model="item.category" placeholder="请选择" size="small">
                      <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4"><el-button type="danger" size="small" icon="el-icon-delete" plain @click="handleDeleteListPageItem('kt',index)"></el-button></el-col>
                </el-row>
                <el-button type="text" @click="handleAddListPageItem('kt')"> <i class="el-icon-circle-plus-outline"></i> 新增分类信息</el-button>
              </el-tab-pane>
              <el-tab-pane label="餐厅">
                <el-row :gutter="10" class="mgb10" v-for="(item, index) in listpage.ct" :key="index">
                  <el-col :span="10">
                    <el-input size="small" v-model="item.name" placeholder="分类名称"></el-input>
                  </el-col>
                  <el-col :span="10">
                    <el-select v-model="item.category" placeholder="请选择" size="small">
                      <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4"><el-button type="danger" size="small" icon="el-icon-delete" plain @click="handleDeleteListPageItem('ct',index)"></el-button></el-col>
                </el-row>
                <el-button type="text" @click="handleAddListPageItem('ct')"> <i class="el-icon-circle-plus-outline"></i> 新增分类信息</el-button>
              </el-tab-pane>
              <el-tab-pane label="卧室">
                <el-row :gutter="10" class="mgb10" v-for="(item, index) in listpage.ws" :key="index">
                  <el-col :span="10">
                    <el-input size="small" v-model="item.name" placeholder="分类名称"></el-input>
                  </el-col>
                  <el-col :span="10">
                    <el-select v-model="item.category" placeholder="请选择" size="small">
                      <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4"><el-button type="danger" size="small" icon="el-icon-delete" plain @click="handleDeleteListPageItem('ws',index)"></el-button></el-col>
                </el-row>
                <el-button type="text" @click="handleAddListPageItem('ws')"> <i class="el-icon-circle-plus-outline"></i> 新增分类信息</el-button>
              </el-tab-pane>
              <el-tab-pane label="其他">
                <el-row :gutter="10" class="mgb10" v-for="(item, index) in listpage.qt" :key="index">
                  <el-col :span="10">
                    <el-input size="small" v-model="item.name" placeholder="分类名称"></el-input>
                  </el-col>
                  <el-col :span="10">
                    <el-select v-model="item.category" placeholder="请选择" size="small">
                      <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4"><el-button type="danger" size="small" icon="el-icon-delete" plain @click="handleDeleteListPageItem('qt',index)"></el-button></el-col>
                </el-row>
                <el-button type="text" @click="handleAddListPageItem('qt')"> <i class="el-icon-circle-plus-outline"></i> 新增分类信息</el-button>
              </el-tab-pane>
              <el-tab-pane label="风格">
                <el-row :gutter="10" class="mgb10" v-for="(item, index) in listpage.fg" :key="index">
                  <el-col :span="10">
                    <el-input size="small" v-model="item.name" placeholder="分类名称"></el-input>
                  </el-col>
                  <el-col :span="10">
                    <el-select v-model="item.category" placeholder="请选择" size="small">
                      <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4"><el-button type="danger" size="small" icon="el-icon-delete" plain @click="handleDeleteListPageItem('fg',index)"></el-button></el-col>
                </el-row>
                <el-button type="text" @click="handleAddListPageItem('fg')"> <i class="el-icon-circle-plus-outline"></i> 新增分类信息</el-button>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wx_misc_set',
  data () {
    return {
      listpage: {
        kt: [],
        ct: [],
        ws: [],
        qt: [],
        fg: []
      },
      categories: []
    }
  },
  methods: {
    handleAddListPageItem (item) {
      this.listpage[item].push({ item: '', name: '', category: '' })
    },
    handleDeleteListPageItem (item, index) {
      this.listpage[item].splice(index, 1)
    },
    getCategoryData () {
      var that = this
      this.axios.get('admin/category/all')
        .then(function (response) {
          if (response.data.code === 0) {
            that.categories = response.data.categories
          }
        })
    },
    getListCategoryData () {
      var that = this
      this.axios.get('admin/misc/listpage')
        .then(function (response) {
          that.listpage.kt = response.data.kt === undefined ? [] : response.data.kt
          that.listpage.ws = response.data.ws === undefined ? [] : response.data.ws
          that.listpage.ct = response.data.ct === undefined ? [] : response.data.ct
          that.listpage.qt = response.data.qt === undefined ? [] : response.data.qt
          that.listpage.fg = response.data.fg === undefined ? [] : response.data.fg
        })
    },
    submitListCategoryInfo () {
      console.log(this.listpage)
      const listpage = this.listpage
      let flag = true
      let number = 0
      for (const key in listpage) {
        const item = listpage[key]
        item.forEach(element => {
          if (element.name === '' || element.category === '') {
            flag = false
          } else {
            number = number + 1
          }
        })
      }
      if (flag === false) {
        this.$message.error('不符合更新条件')
      } else {
        var that = this
        this.axios.post('admin/misc/listpage', this.listpage)
          .then(function (response) {
            if (response.data.code === 0) {
              console.log(response.data)
              that.$message.success('更新成功！')
            }
          })
      }
    }
  },
  mounted () {
    this.getCategoryData()
    this.getListCategoryData()
  }
}
</script>

<style scoped>
.mgb10{
  margin-bottom: 10px;
}
</style>
